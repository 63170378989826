// eslint-disable-next-line @typescript-eslint/naming-convention
export const KEY_FORM = 'form';

export const enum EnumForm {
	Routing = 'SCA-ROUTING',
	SolicitudTransbordo = 'SCA-SOLICITUD-TRANSBORDO',
	SeguimientoOperativo = 'SCA-SEGUIMIENTO-OPERATIVO',
	ProvisionAdministrativa = 'SCA-PROVISION-ADMINISTRATIVA',
	Contrato = 'SCA-CONTRATO',
	Nave = 'SCA-NAVE',
	CondicionServicio = 'SCA-CONDICION',
	CondicionServicioSMA = 'SMA-CONDICION-SERVICIO',
	Documento = 'SCA-DOCUMENTO',
	ProgramaNave = 'SCA-PROGRAMA-NAVE',
	ControlContenedor = 'SCA-CONTROL-CONTENEDOR',
	ConfiguracionEmisionDocumento = 'SCA-CONFIG-SEGUIMIENTO-OPERATIVO',
	EmisionDocumento = 'SCA-EMISION-DOCUMENTO',
	Plantilla = 'SCA-PLANTILLA',
	SeguimientoOperativoRo = 'SCA-SEGUIMIENTO-RO',

	//Formulario de Maestros
	AgenteExtranjero = 'SMA-AGENTE-EXTRANJERO',
	Transportista = 'SMA-TRANSPORTISTA',
	AgenteCarga = 'SMA-AGENTE-CARGA',
	AgenteMaritimo = 'SMA-AGENTE-MARITIMO',
	terminalPortuario = 'SMA-TERMINAL-PORTUARIO',
	Depostio = 'SMA-DEPOSITO',
	Cliente = 'SMA-CLIENTE',
	EntidadExtranjera = 'SMA-ENTIDAD-EXTRANJERA',
	Contacto = 'SMA-ENTIDAD-CONTACTO',
	CompaniaSeguro = 'SMA-COMPANIA-SEGURO',
	Local = 'SMA-ENTIDAD-LOCAL',
	Incidencia = 'SMA-INCIDENCIA',
}
